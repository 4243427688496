import { PROFILE_DEFAULTS } from '@smartflip/data-constants';
import { Address } from './address';

export class Borrower {
  name: string;
  address?: Address;
  phone?: string;
  email?: string; // allow for using of an email that is not used for login

  constructor(name: string, address?: Address, phone?: string, email?: string) {
    this.name = name;
    this.address = address ?? new Address();
    this.phone = phone;
    this.email = email;
  }
}

export class SmartFlipUser {
  id?: string;
  firstName?: string;
  lastName?: string;
  fullName: string;
  name?: string;
  email?: string;
  img?: string;
  borrowerInformation?: Borrower;
  defaults?: {
    duration?: number;
    loanpoints?: number;
    loanrate?: number;
    appraisalFee?: number;
    state?: string;
    franchiseFees?: number;
    franchiseTransactionFeesPercent?: number;
    commpercent?: number;
    inspectionFees?: number;
    attorneyFeesSeller?: number;
  };
  subscription?: {
    plan?: {
      name: string;
    };
  };
  hasRefreshedDataFor?: Record<string, number>;

  constructor(fullName = '', email = '', firstName = '', lastName = '') {
    this.firstName = firstName;
    this.lastName = lastName;
    this.fullName = fullName;
    this.email = email;
    this.defaults = PROFILE_DEFAULTS;
    this.hasRefreshedDataFor = {};
    // TODO: add missing defaults
    this.borrowerInformation = new Borrower('Borrower');
  }
}
