import { Inject, Injectable, inject } from '@angular/core';
import { BehaviorSubject, Subject, combineLatest, take } from 'rxjs';
import {
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  Firestore,
  collection,
  collectionData,
  doc,
  docData,
  getDoc,
} from '@angular/fire/firestore';
import { ADMIN_USER, ADMIN_USER_DEV } from '@smartflip/data-constants';
import { Capacitor } from '@capacitor/core';

type subscriptionType = Record<
  string,
  DocumentReference<unknown, DocumentData>
>[];

@Injectable({
  providedIn: 'root',
})
export class CustomerService {
  private firestore: Firestore = inject(Firestore);
  private currentCustomer: Subject<Record<string, unknown>> =
    new BehaviorSubject({});
  public currentCustomer$ = this.currentCustomer.asObservable();
  private hasActiveSubscription: Subject<boolean> = new BehaviorSubject(false);
  public hasActiveSubscription$ = this.hasActiveSubscription.asObservable();
  private userId: string = '';

  constructor(
    @Inject('environment')
    private environment: Record<string, unknown>,
  ) {}

  public async getCustomerByUserId(
    userId: string,
  ): Promise<Record<string, unknown>> {
    const promise: Promise<Record<string, unknown>> = new Promise((resolve) => {
      const path = `customers/${userId}`;
      const customerRef = doc(this.firestore, path);

      this.userId = userId;

      combineLatest([
        docData(customerRef),
        collectionData(collection(this.firestore, path, 'subscriptions')),
      ])
        .pipe(take(1))
        .subscribe(
          ([customer, subscriptions]: [
            DocumentData | undefined,
            subscriptionType,
          ]) => {
            const customerWithSubscriptions = {
              ...customer,
              subscriptions,
            };
            // console.log('customerWithSubscriptions', customerWithSubscriptions);
            this.populateSubscriptions(customerWithSubscriptions);
            this.setActiveSubscriptionStatus(customerWithSubscriptions);

            resolve(
              customerWithSubscriptions as unknown as Record<string, unknown>,
            );
          },
        );
    });

    return promise;
  }

  private populateSubscriptions(customer: { subscriptions: subscriptionType }) {
    const { subscriptions } = customer;

    if (subscriptions.length === 0) {
      this.currentCustomer.next(customer);
    } else {
      getDoc(subscriptions[0]['product'])
        .then((product: DocumentSnapshot<unknown | DocumentData>) => {
          this.currentCustomer.next({
            ...customer,
            subscriptions: (subscriptions as Record<string, unknown>[]).map(
              (subscription) => ({
                ...subscription,
                product: product.data(),
              }),
            ),
          });
        })
        .catch((error: string) => {
          console.log(error);
        });
    }
  }

  private setActiveSubscriptionStatus(customer: {
    subscriptions: Record<string, unknown>[];
  }) {
    let hasSubscription = false;

    if (
      this.environment['free'] ||
      this.userId === ADMIN_USER ||
      this.userId === ADMIN_USER_DEV ||
      Capacitor.isNativePlatform() // TODO: remove once Apple in-app purchases are enabled
    ) {
      hasSubscription = true;
    } else if (customer?.subscriptions?.['length'] > 0) {
      hasSubscription = (
        customer?.subscriptions as Record<string, unknown>[]
      ).reduce((status, sub) => sub['status'] === 'active' || status, false);
    }

    this.hasActiveSubscription.next(hasSubscription);
  }
}
