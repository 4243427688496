import { ButtonStates, HomeListButton } from './home-list.models';

export const subscriptionWarningTexts = [
  'Upgrade your plan to add more properties',
  'Upgrade your plan to export all property data',
  'Upgrade your plan to access the summary report',
];

export const missingPropertiesWarningTexts = [
  '',
  'You have nothing to export',
  'Record sold properties to enable',
];

export const homeButtonClasses = [
  'HomeList__ButtonColumn--Left',
  '',
  'HomeList__ButtonColumn--Right',
];

export const homeListButtons: HomeListButton[] = [
  {
    text: 'Add New Property',
    icon: 'add',
    action: 'newProperty',
    disabled: false,
    id: 'add-new-property-button',
  },
  {
    text: 'Export Data',
    icon: 'download',
    action: 'exportData',
    disabled: false,
    id: 'export-data-button',
  },
  {
    text: 'Download Summary',
    icon: 'report',
    action: 'downloadSummary',
    disabled: false,
    id: 'download-summary-button',
  },
];

export const defaultButtonStates: ButtonStates = homeListButtons.reduce(
  (acc, button) => ({
    ...acc,
    [button.id]: {
      disabled: button.disabled,
      text: button.text,
    },
  }),
  {},
);

export const downloadErrorMessage =
  'Could not download your report. Please try again or contact support';
