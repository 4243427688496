import { FormGroupFields } from '@smartflip/data-models';

// keys for the individual forms
export const formSectionsList: FormGroupFields[] = [
  'gen',
  'owner',
  'mortgage',
  'summary',
  'acq',
  'carry',
  'repair',
  'selling',
  /* 'repairdetail' ,*/ 'other',
];
