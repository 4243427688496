import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, Observable, combineLatest, of } from 'rxjs';
import { delay, tap } from 'rxjs/operators';
import { LoadingService, SnackbarService } from '@smartflip/ui-utils';
import { propertyCategories } from '@smartflip/data-constants';
import { CategorizedProperties, Property } from '@smartflip/data-models';
import {
  CustomerService,
  PropertyService,
  UserService,
  createPropertyList,
} from '@smartflip/helper-utils';
import { AddPropertyModalComponent } from '../property-details/components/add-property-modal/add-property-modal.component';
import {
  defaultButtonStates,
  downloadErrorMessage,
  homeButtonClasses,
  homeListButtons,
  subscriptionWarningTexts,
} from './home-list.constants';
import { downloadSummaryPDF, setButtonStates } from './home-list.helper';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ButtonStates, HomeListButtonActionType } from './home-list.models';
import { DocumentData } from 'firebase/firestore';

@Component({
  selector: 'app-home-list',
  templateUrl: './home-list.component.html',
  styleUrls: ['./home-list.component.scss'],
})
export class HomeListComponent implements OnInit {
  properties$: Observable<CategorizedProperties>;
  public loading = true;
  public errorMessage: string = '';
  public showBanner = true;
  public cats = propertyCategories;
  public buttonList = homeListButtons;
  public subscriptionWarningTexts = subscriptionWarningTexts;
  public buttonStates: BehaviorSubject<ButtonStates> = new BehaviorSubject(
    defaultButtonStates,
  );
  public buttonStates$ = this.buttonStates.asObservable();

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private http: HttpClient,
    private propService: PropertyService,
    private customerService: CustomerService,
    private loadingService: LoadingService,
    private snackbarService: SnackbarService,
    private userService: UserService,
  ) {}

  public hideBanner() {
    this.showBanner = false;
  }

  public noProperties(propertyList: Property[]): boolean {
    return propertyList.length === 0;
  }

  public getExtraButtonColumnClass(buttonIndex: number): string {
    return homeButtonClasses[buttonIndex];
  }

  public handleButtonClick(actionType: HomeListButtonActionType) {
    const actionMap = {
      newProperty: this.newProperty,
      downloadSummary: this.downloadSummary,
      exportData: this.exportData,
    };

    actionMap[actionType].call(this);
  }

  private newProperty() {
    this.dialog
      .open(AddPropertyModalComponent)
      .afterClosed()
      .subscribe((newPropertyData) => {
        if (newPropertyData) {
          this.addNewProperty(newPropertyData);
        }
      });
  }

  private exportData() {
    this.router.navigate(['/home/export']);
  }

  private downloadSummary() {
    // TODO: Connect to a button and replace with env vars for prod and dev environments
    //  consider keeping one for local testing as well as seen below
    this.loading = true;
    downloadSummaryPDF(this.http, this.userService).subscribe(
      (downloadState) => {
        if (downloadState instanceof HttpErrorResponse) {
          const fourSeconds = 4000;

          this.snackbarService.presentToast(
            'top',
            downloadErrorMessage,
            fourSeconds,
            null,
            'error-toaster',
          );
        }

        this.loading = false;
      },
    );
  }

  private async addNewProperty(newPropertyData) {
    const loader = await this.loadingService.showLoading();

    this.propService
      .addProperty(newPropertyData)
      .then((newPropertyId: string) => {
        this.propService.updateProperty('gen', newPropertyData, newPropertyId);
        this.router.navigate(['/home', newPropertyId]);
      })
      .finally(() => loader.dismiss());
  }

  private limitViewByPlan(): Observable<[boolean, DocumentData[]]> {
    return combineLatest([
      this.customerService.hasActiveSubscription$,
      this.propService.properties$,
    ]).pipe(
      delay(600),
      tap(([hasActiveSubscription, properties]) => {
        const updatedProperties = createPropertyList(
          properties as Property[],
          hasActiveSubscription,
        );
        const updatedButtonStates = setButtonStates(
          updatedProperties,
          hasActiveSubscription,
        );

        this.showBanner = !hasActiveSubscription;
        this.properties$ = of(updatedProperties);
        this.buttonStates.next(updatedButtonStates);
      }),
    );
  }

  ngOnInit(): void {
    this.limitViewByPlan().subscribe(() => {
      this.loading = false;
    });
  }
}
