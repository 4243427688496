@if (this.roi.formGroups && routeLoaded && propertyId) {
  <div class="PropertyDetails">
    <mat-tab-group (selectedIndexChange)="handleTabChange($event)">
      @for (tabSection of tabSections; track tabSection) {
        <mat-tab [labelClass]="'details-tab-' + tabSection.label">
          <ng-template mat-tab-label>
            <mat-icon color="primary">{{ tabSection.icon }}</mat-icon>
            <span
              class="PropertyDetails__TabLabel"
              [innerHTML]="tabSection.label"
            ></span>
          </ng-template>

          @switch (tabSection.template) {
            @case ('general') {
              <app-general-details
                [propertyID]="propertyId"
              ></app-general-details>
            }
            @case ('costs') {
              <app-cost-details [propertyID]="propertyId"></app-cost-details>
            }
            @case ('analytics') {
              <app-profit-details></app-profit-details>
            }
            @case ('comparables') {
              @defer (when showComps) {
                <app-comparables
                  [propertyIsSet]="propertyIsSet$ | async"
                  [(error)]="compError"
                ></app-comparables>
              }
            }
          }
        </mat-tab>
      }
    </mat-tab-group>
  </div>
} @else {
  <!-- LOADING INDICATOR -->
  <div class="PropertyDetails__SpinnerWrapper">
    <div class="PropertyDetails__Spinner">
      <mat-spinner></mat-spinner>
    </div>
  </div>
}
