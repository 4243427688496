import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, BehaviorSubject, take } from 'rxjs';
import {
  Firestore,
  deleteDoc,
  doc,
  getDoc,
  setDoc,
} from '@angular/fire/firestore';
import { User } from 'firebase/auth';
import * as Sentry from '@sentry/browser';
import { SmartFlipUser } from '@smartflip/data-models';
import { FirebaseAnalyticsService } from '@smartflip/data-firebase';
import { AuthService } from '@smartflip/authentication';
import { PROFILE_DEFAULTS } from '@smartflip/data-constants';
import { parseJwt } from '../user.service.helper';
import { Logging } from './logging.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private loggedInUser: BehaviorSubject<SmartFlipUser> = new BehaviorSubject(
    null as unknown as SmartFlipUser,
  );
  public loggedInUser$: Observable<SmartFlipUser> = this
    .loggedInUser as Observable<SmartFlipUser>;
  public currentUserId: string = '';
  private angularFirestore: Firestore = inject(Firestore);

  constructor(
    private authService: AuthService,
    private router: Router,
    private firebaseAnalyticsService: FirebaseAnalyticsService,
    private logger: Logging,
  ) {
    this.authService.authState$.subscribe(this.getUserFromAuthState.bind(this));
  }

  // Returns current firebase user
  get currentUser(): User | null {
    return this.authService.authenticated
      ? this.authService.auth.currentUser
      : null;
  }

  // pull the user's metadata from FB and set service property
  private async setLoggedInUser(
    uid: string,
    displayName: string | null,
    email = '',
  ): Promise<void> {
    if (!uid) return;

    const path = `Users/${uid}`; // Endpoint on firebase
    const userRef = doc(this.angularFirestore, path);
    const userSnap = await getDoc(userRef);
    const user = userSnap.data() as SmartFlipUser;

    if (user) {
      this.patchUserDefaults(user);
    } else {
      await this.createNewUserRecord(uid, displayName, email);
    }
  }

  private async createNewUserRecord(
    uid: string,
    displayName: string | null,
    email = '',
  ): Promise<void> {
    const [firstName, lastName] = displayName?.split(' ') || ['', ''];
    // TODO: user the smartflipuser constructor
    const user: SmartFlipUser = {
      id: uid,
      email,
      fullName: displayName || '',
      firstName,
      lastName,
      defaults: PROFILE_DEFAULTS,
      borrowerInformation: {
        name: displayName || '',
      },
    };

    await this.updateUserData(user);
  }

  private async getUserFromAuthState(authState: User | null): Promise<void> {
    if (authState) {
      const { displayName, email, uid } = authState;

      Sentry.setUser({ email: email || 'unknown user' });
      this.firebaseAnalyticsService.setUserId({ userId: uid });
      this.firebaseAnalyticsService.logEvent({
        name: 'login',
        params: { userId: uid },
      });
      this.currentUserId = uid;
      // Get the SmartFlip User data from firebase and set it to the LoggedInUser property
      this.setLoggedInUser(uid, displayName, email ?? '');

      this.logger.trace(`User logged in, ${uid} ${email}`);
    } else {
      this.router.navigate(['/login']);
    }
  }

  // patches data to the user's metadata in firebase
  public updateUserData(newData: SmartFlipUser): Promise<void> {
    if (!this.currentUser) return new Promise(() => null);

    const path = `Users/${this.currentUserId}`; // Endpoint on firebase
    const userAsObject = { ...newData };
    const userRef = doc(this.angularFirestore, path);

    this.logger.trace(`User updated, ${JSON.stringify(userAsObject)}`);

    return setDoc(userRef, userAsObject, { merge: true });
  }

  // Called every time setLoggedInUser is run
  private patchUserDefaults(loggedInUserSnapshot: unknown): void {
    const loggedInUser: SmartFlipUser = loggedInUserSnapshot as SmartFlipUser;
    if (!loggedInUser) return;

    loggedInUser.id = this.currentUserId;
    loggedInUser.defaults = {
      state: '',
      ...loggedInUser.defaults,
    };
    this.loggedInUser.next(loggedInUser);
  }

  /**
   * @description removes a user from the database and logs them out of firebase
   * @returns err or success
   *
   */
  public async deleteUser(): Promise<boolean> {
    const userDocument = doc(
      this.angularFirestore,
      `Users/${this.currentUserId}`,
    );
    const user = await this.authService.auth.currentUser;

    try {
      await deleteDoc(userDocument);
      this.logger.trace(`User deleted, ${user?.uid}`);
    } catch (err) {
      console.log(err);

      return false;
    }

    try {
      const token = (await user?.getIdToken()) || '';
      const tokenData = parseJwt(token);
      const provider = tokenData.firebase.sign_in_provider;
      const providerName = provider.split('.')[0];

      await this.authService.login(providerName);
      user?.delete();
    } catch (err) {
      console.log(err);

      return false;
    }

    return true; // success
  }

  // Track how often a user has refreshed data for a property
  public userRefreshedDataFor(propertyID: string): void {
    this.loggedInUser$.pipe(take(1)).subscribe((user) => {
      const refreshCount = user.hasRefreshedDataFor?.[propertyID]
        ? user.hasRefreshedDataFor[propertyID] + 1
        : 1;

      this.updateUserData({
        ...user,
        hasRefreshedDataFor: {
          ...user.hasRefreshedDataFor,
          [propertyID]: refreshCount,
        },
      });
    });
  }
}
