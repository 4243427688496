<div class="HomeList">
  <div class="container">
    <div class="row">
      <smartflip-financial-aggregates
        class="HomeList__Aggregates"
      ></smartflip-financial-aggregates>
    </div>
  </div>
  <div
    class="HomeList__Alert alert alert-primary d-flex"
    role="alert"
    [ngClass]="{ 'HomeList--Hidden': !showBanner }"
  >
    <span
      >The free tier allows for only one property at a time. <br />
      <a routerLink="/home/subscribe">Upgrade</a>
      to one of professional plans to add more properties.
    </span>
    <button
      type="button"
      class="close"
      (click)="hideBanner()"
      data-dismiss="alert"
      aria-label="Close Alert"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  @if (buttonStates$ | async; as buttonStates) {
    <span class="HomeList__ButtonWrapper">
      @for (button of buttonList; track button; let i = $index) {
        <div
          class="HomeList__ButtonColumn"
          [ngClass]="getExtraButtonColumnClass(i)"
        >
          <button
            mat-button
            mat-raised-button
            color="primary"
            id="button.id || button.action"
            data-test-id="button.action + 'Button'"
            class="HomeList__Button"
            (click)="handleButtonClick(button.action)"
            [disabled]="buttonStates[button.id].disabled"
            [innerHTML]="button.text"
          ></button>
          @if (buttonStates[button.id].disabled) {
            <small
              class="HomeList__ButtonWarning"
              [innerHTML]="buttonStates[button.id].text"
            ></small>
          }
        </div>
      }
    </span>
  }

  @if (loading) {
    <app-loading class="HomeList__Spinner"></app-loading>
  }

  @if (properties$ | async; as properties) {
    @for (category of ['potential', 'active', 'sold']; track category) {
      <h3 class="HomeList__Title">
        <span [innerHTML]="category"></span>
        <span class="HomeList__Hint">({{ cats[category].hint }})</span>
      </h3>

      @for (property of properties[category]; track property) {
        <app-home-list-card
          [property]="property"
          class="HomeList__Card"
        ></app-home-list-card>
      }

      @if (noProperties(properties[category])) {
        <mat-card class="HomeList__Card">
          <p>
            You have no {{ category }} properties.
            {{ cats[category].howToAdd }} to get started.
          </p>
        </mat-card>
      }
    }
  }
</div>
