import { Injectable } from '@angular/core';
import { createPropertyList, PropertyService } from '@smartflip/helper-utils';
import { CategorizedProperties, Property } from '@smartflip/data-models';
import { BehaviorSubject, Observable } from 'rxjs';
import { totalExpenses } from './aggregate.helper';

@Injectable({
  providedIn: 'root',
})
export class AggregateService {
  private allProperties: CategorizedProperties | undefined;
  private totalBorrowed: BehaviorSubject<number> = new BehaviorSubject(0);
  private totalRenovation: BehaviorSubject<number> = new BehaviorSubject(0);
  private totalProfits: BehaviorSubject<number> = new BehaviorSubject(0);
  public totalBorrowed$: Observable<number> = this.totalBorrowed.asObservable();
  public totalRenovation$: Observable<number> =
    this.totalRenovation.asObservable();
  public totalProfits$: Observable<number> = this.totalProfits.asObservable();

  constructor(private propertyService: PropertyService) {
    this.pullPropertyData();
  }

  private async pullPropertyData() {
    this.propertyService.properties$.subscribe((properties) => {
      console.log('properties', properties);
      const updatedProperties = createPropertyList(
        properties as Property[],
        true,
      );

      this.allProperties = updatedProperties;
      this.totalBorrowed.next(this.getTotalBorrowed());
      this.totalRenovation.next(this.getTotalRenovation());
      this.totalProfits.next(this.getTotalProfits());
    });
  }

  public getTotalBorrowed(): number {
    const totalBorrowed =
      this.allProperties?.active.reduce((total, property) => {
        const loanAsNumber =
          property.summary.loan?.replace('$', '').replace(/,/g, '') || 0;

        return total + (loanAsNumber ? parseFloat(loanAsNumber) : 0);
      }, 0) || 0;

    return totalBorrowed || 0;
  }

  public getTotalRenovation(): number {
    const { active } = this.allProperties || {};
    const totalActiveRenovation =
      active?.reduce((total, property) => total + property.repair.total, 0) ||
      0;
    // const totalSoldRenovation =
    //   sold?.reduce((total, property) => total + property.repair.total, 0) || 0;

    return totalActiveRenovation;
  }

  public getTotalProfits(): number {
    const { sold } = this.allProperties || {};
    const totalSoldProfits = sold?.reduce(
      (total, property) => total + this.getProfitData(property),
      0,
    );

    return totalSoldProfits || 0;
  }

  private getProfitData(property: Property): number {
    const { sell } = property.summary;

    return sell -  totalExpenses(property);
  }
}
