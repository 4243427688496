const apiHostUrl = 'https://us-central1-property-roi.cloudfunctions.net';

export const environment = {
  production: true,
  logLevel: 'warn',
  firebase: {
    apiKey: 'AIzaSyA2Nx5wzC_DpSSOuC5BAtyJwP2CkLszQk0',
    authDomain: 'app.thesmartflip.com',
    databaseURL: 'https://property-roi.firebaseio.com',
    projectId: 'property-roi',
    storageBucket: 'property-roi.appspot.com',
    messagingSenderId: '1085993728945',
    appId: '1:1085993728945:web:bb5296067b82fce6',
    measurementId: 'G-KPZFT74KDX',
  },
  download: {
    functionUrl: `${apiHostUrl}/downloadPDF`,
    hostUrl: 'property-roi.web.app',
  },
  sentry: {
    dsn: 'https://0af35d3b61d71f8f03780558af0b3320@o4506808376360960.ingest.sentry.io/4506808378392576',
  },
  googleApiKey: 'AIzaSyBJtoUd2vaGsB-eNSrhxqwlnrwr5wVuM8Y',
  apiHostUrl,
  attomApiKey: '9cf99bd797404c2f6d6e5fac040f17f1',
  attomApiUrlV1: 'https://api.gateway.attomdata.com/propertyapi/v1.0.0/',
  attomApiUrlV2: 'https://api.gateway.attomdata.com/property/v2/',
  stripePublishableKey:
    'pk_live_51N2iduDm8a3KnFeiOGe5MGNEnn1X9iamyeKDpSxEnLyI6RyObww3wXvlE24OyGMBt2njJwiD0za8y095K2JgvGjW00PpSPi5TJ',
  datafinitiKey:
    'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIzdmJxbjJ6ajJucG5yYnVmeWkzeThtajVyZWYwOXlseCIsImlzcyI6ImRhdGFmaW5pdGkuY28ifQ.A2ufHHap0ZAqV75WIk78EJNpAHUwGaOStsQkuqIbzsWXsgO-CyX97YhBmn2znevkzEKfeqyQ_Lp1T4B1V58Lhafjb3KQEECRXMlWzwtWS3fJsrDcuHT-V611pYoTx8WYp5QFENPjJxUcqnKHDQEastooXT01iQB5DJCKIbizmjjCi5hIaK-_D-8WvyHCrYB5aRmRCS_Yrf27fELzsGY42uCG0lzTwnmu7uWVqZeW8BK3J3rJEBpyYQJD-1j02NGjhJYQ6DjckbrbfjCb_Z2Jysi3GWibaNQSO9qJN_tRyMLSQatawYUtCoFH4a0T1uxikSOt5Pi-SxZkQlkNnhaIw4BR5y5zu7cGYy-DvgZJ7vHAS8dyMyKEThC4Z2ZhuMnNqbHJtgEk075tB17M0eEsa4O6ijSlozwDccPp0sX3Psan0emAJWzeM288bgT-Zcw-FyM7Rbsn4A2dR0ThgS8NXwRnZiR3-C6C9D4Na50bV-EbR43FFx2muiPUrSX2xN8A-RspIICcauo4Vwxi02WK3ACTSD2Mq3HrqcFXV8DLnXMalS_RRALQoGDCSHlpOK4oWkgU-UQ8K2ZcoCvOnXqvb_Os22gDosXA1apL7pCygxFWP1Sa4sg1VC6zXSGfF_eivmkUEAkue3aJ8kDV2gyM-LUA6WAl2wTQpjFp5DyK0Nw',
};
