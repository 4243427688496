import { Routes } from '@angular/router';
import { AuthGuardService } from '@smartflip/authentication';
import { HomeListComponent } from '../views/home-list/home-list.component';
import { HomeComponent } from '../views/home/home.component';
import { LenderFormComponent } from '../views/lender/lender-form/lender-form.component';
import { LoginComponent } from '@smartflip/login';
import { PropertyDetailsComponent } from '../views/property-details/property-details.component';

export const routes: Routes = [
  { path: 'login', component: LoginComponent },
  {
    path: 'reports',
    loadChildren: () =>
      import('@smartflip/reporting').then((m) => m.reportingRoutes),
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [AuthGuardService],
    children: [
      { path: 'lender', component: LenderFormComponent }, // May need to be deprecated
      {
        path: 'loan-application',
        loadChildren: () =>
          import('@smartflip/loan-application').then(
            (m) => m.FeaturesLoanApplicationModule,
          ),
      },
      {
        path: 'subscribe',
        loadChildren: () =>
          import('@smartflip/stripe').then((m) => m.StripePaymentModule),
      },
      {
        path: 'export',
        loadChildren: () =>
          import('@smartflip/data-export').then(
            (m) => m.FeaturesFeatDataExportModule,
          ),
      },
      { path: 'properties', component: HomeListComponent },
      {
        path: 'profile',
        loadChildren: () =>
          import('@smartflip/user-profile-view').then(
            (m) => m.UserProfileViewModule,
          ),
      },
      {
        path: 'support',
        loadChildren: () =>
          import('@smartflip/feat-support').then(
            (m) => m.FeaturesFeatSupportModule,
          ),
      },
      { path: ':id', component: PropertyDetailsComponent },
      { path: '', redirectTo: 'properties', pathMatch: 'full' },
    ],
  },
  { path: '**', redirectTo: 'home', pathMatch: 'full' },
];
