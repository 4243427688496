import { HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, catchError, Observable, of, tap } from 'rxjs';
import { CategorizedProperties } from '@smartflip/data-models';
import { environment } from '../../../environments/environment';
import {
  missingPropertiesWarningTexts,
  subscriptionWarningTexts,
} from './home-list.constants';
import { ButtonStates } from './home-list.models';

export const hasNoProperties = (propertyList: CategorizedProperties) =>
  propertyList.active.length === 0 &&
  propertyList.potential.length === 0 &&
  propertyList.sold.length === 0;

export const downloadSummaryPDF = (http, userService): Observable<Blob> => {
  const uid = userService.currentUserId;
  const hostUrl = environment.download.hostUrl;
  const reportPath = `https://${hostUrl}/reports/summary/${uid}`;
  const functionsHostPath = environment.download.functionUrl;
  const summaryPath = `${functionsHostPath}?url=${reportPath}&file=fake.pdf`;

  return http.get(summaryPath, { responseType: 'blob' }).pipe(
    catchError((error) => of(error)),
    tap((data: BlobPart | HttpErrorResponse) => {
      if (data instanceof HttpErrorResponse) {
        if (data.status === 404) {
          throw new Error(missingPropertiesWarningTexts[1]);
        } else if (data.status === 402) {
          throw new Error(subscriptionWarningTexts[2]);
        }
      } else {
        const blob = new Blob([data], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);

        window.open(url);
      }
    }),
  );
};

export const setButtonStates = (
  propertiesByCategory,
  hasActiveSubscription: boolean,
) => {
  const hasAnyProperties = hasNoProperties(propertiesByCategory) === false;
  const hasSoldProperties = propertiesByCategory.sold.length > 0;
  const buttonStates = {
    'add-new-property-button': {
      disabled: !hasActiveSubscription && hasAnyProperties,
      text: !hasAnyProperties ? '' : 'Upgrade your plan to add more properties',
    },
    'export-data-button': {
      disabled: !hasActiveSubscription || !hasAnyProperties,
      text: hasAnyProperties
        ? subscriptionWarningTexts[1]
        : missingPropertiesWarningTexts[1],
    },
    'download-summary-button': {
      disabled: !hasActiveSubscription || !hasSoldProperties,
      text: hasSoldProperties
        ? subscriptionWarningTexts[2]
        : missingPropertiesWarningTexts[2],
    },
  };

  return buttonStates;
};

export const toggleButtonDisabledState = (
  currentButtonStates: BehaviorSubject<ButtonStates>,
  buttonToUpdate: string,
  newState: boolean,
) => {
  const updatedButtonStates = { ...currentButtonStates.value };
  const buttonStates = {
    ...updatedButtonStates,
    [buttonToUpdate]: {
      ...updatedButtonStates[buttonToUpdate],
      disabled: newState,
    },
  };

  return buttonStates;
};
