import {
  Component,
  Input,
  ViewChild,
  OnInit,
  AfterViewInit,
  EventEmitter,
  Output,
  HostListener,
  OnChanges,
} from '@angular/core';
import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';
import { SelectionModel } from '@angular/cdk/collections';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Comparable } from '@smartflip/data-models';
import { compare } from '@smartflip/utils-data';
import {
  comparablesColumns,
  comparablesHiddenColumns,
} from '@smartflip/data-constants';
import { CurrencyPipe } from '@angular/common';
import { statusIconMap } from '../comparables.constants';
import { restoreHiddenColumns } from './comparables-table.helper';

@Component({
  selector: 'app-comparables-table',
  templateUrl: './comparables-table.component.html',
  styleUrls: ['./comparables-table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed, void', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*', paddingBottom: '1rem' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'),
      ),
      transition(
        'expanded <=> void',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'),
      ),
    ]),
  ],
})
export class ComparablesTableComponent
  implements OnInit, AfterViewInit, OnChanges
{
  @Input() compsSource: Comparable[];
  @Input() highlightedAddress: string | null;
  @Output() selectionChange: EventEmitter<SelectionModel<Comparable>> =
    new EventEmitter();
  @Output() hoverChange: EventEmitter<Comparable> = new EventEmitter();
  @ViewChild(MatSort) sort: MatSort;
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.showHideColumnsPerSize();
  }

  columnsToDisplay = comparablesColumns;
  expandedElement: Comparable | null;
  compsTableSource: MatTableDataSource<Comparable>;

  public compSelection = new SelectionModel<Comparable>(true, []);
  public showStatusHeader = false;

  public headerLookup = {
    salesStatus: 'Status',
    street: 'Address',
    distanceFromSource: 'Dist.',
    lastSoldPrice: 'Last Sale',
    finishedSqFt: 'SqFt',
    bedrooms: 'Beds',
    bathrooms: 'Baths',
    selectBox: 'Select',
  };

  constructor(private currencyPipe: CurrencyPipe) {}

  ngOnInit(): void {
    this.compsTableSource = new MatTableDataSource(this.compsSource);
    this.compSelection.changed.subscribe(() =>
      this.selectionChange.emit(this.compSelection),
    );
    this.showHideColumnsPerSize();
  }

  ngOnChanges(changes): void {
    if (changes?.highlightedAddress?.currentValue) {
      this.highlightedAddress = changes.highlightedAddress.currentValue;
    }
  }

  ngAfterViewInit() {
    this.compsTableSource.sort = this.sort;
  }

  handleSortChange(sortState: Sort) {
    this.compsTableSource.data.sort((a, b) =>
      compare(
        a[sortState.active],
        b[sortState.active],
        sortState.direction === 'asc',
      ),
    );
    this.expandedElement = null;
  }

  public getIcon(status: string) {
    return statusIconMap[status];
  }

  public propertySelectedInMap(property) {
    // don't return true if both are null
    return (
      this.highlightedAddress && this.highlightedAddress === property?.street
    );
  }

  public alignRight(column: string): boolean {
    return column === 'lastSoldPrice' || column === 'selectBox';
  }

  /**
   * @param column the column to format
   * @param property the property to format
   * @returns the formatted value
   */
  public formatColumnData(column: string, property): string {
    const valueToPipe = property[column];
    let returnValue = '';

    switch (column) {
      case 'lastSoldPrice':
        returnValue = this.currencyPipe.transform(
          valueToPipe,
          'USD',
          'symbol',
          '1.0-0',
        );
        break;
      case 'distanceFromSource':
        returnValue = `${valueToPipe} mi`;
        break;
      default:
        returnValue = valueToPipe;
    }

    return returnValue;
  }

  private showHideColumnsPerSize() {
    const screenWidth = window.innerWidth;
    const hiddenColumns = comparablesHiddenColumns;

    if (screenWidth < 700) {
      this.showStatusHeader = false;
      this.columnsToDisplay = this.columnsToDisplay.filter(
        (col) => !hiddenColumns.includes(col),
      );
    } else if (this.columnsToDisplay.length < 6) {
      // screenWidth is big enough, but also confirm that the columns weren't already hidden
      restoreHiddenColumns(this.columnsToDisplay);
    }
    if (screenWidth > 699) {
      this.showStatusHeader = true;
    }
  }

  /** The label for the checkbox on the passed row */
  public checkboxLabel(row?: Comparable, index?: number): string {
    return `${this.compSelection.isSelected(row) ? 'deselect' : 'select'} row ${
      index + 1
    }`;
  }

  onHoverChange(comparable: Comparable) {
    this.hoverChange.emit(comparable);
  }
}
