export const borrowerFields = [
  {
    type: 'input',
    label: 'Name',
    name: 'name',
    inputType: 'text',
  },
  {
    type: 'input',
    label: 'Address',
    inputType: 'text',
    name: 'street1',
  },
  {
    type: 'input',
    label: "Address (cont'd)",
    inputType: 'text',
    name: 'street2',
  },
  {
    type: 'input',
    label: 'City',
    inputType: 'text',
    name: 'city',
  },
  {
    type: 'input',
    label: 'State',
    inputType: 'text',
    name: 'state',
  },
  {
    type: 'input',
    label: 'Zip',
    inputType: 'text',
    name: 'zip',
  },
  {
    type: 'input',
    label: 'Phone Number',
    inputType: 'text',
    name: 'phone',
  },
  {
    type: 'input',
    label: 'Contact Email',
    inputType: 'text',
    name: 'email',
  },
];
