import { Component } from '@angular/core';
import { AggregateService } from './aggregate.service';
import { Observable } from 'rxjs';
import { propertyHighlights } from '@smartflip/data-constants';
import { AggregateBadge } from '@smartflip/data-models';

@Component({
  selector: 'smartflip-financial-aggregates',
  templateUrl: './financial-aggregates.component.html',
  styleUrls: ['./financial-aggregates.component.scss'],
})
export class FinancialAggregatesComponent {
  public totalBorrowed: Observable<number> =
    this.aggregateService.totalBorrowed$;
  public totalRenovation: Observable<number> =
    this.aggregateService.totalRenovation$;
  public totalProfits: Observable<number> = this.aggregateService.totalProfits$;
  public badges: AggregateBadge[];

  get screenWidth() {
    return Math.max(
      document.documentElement.clientWidth || 0,
      window.innerWidth || 0,
    );
  }

  constructor(private aggregateService: AggregateService) {
    // map the static object to the observables from the aggregate service
    this.badges = Object.entries(propertyHighlights).map(
      ([category, badge]) => ({ ...badge, value: this[category] }),
    );
  }
}
