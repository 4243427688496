import { Calculation, Property } from '@smartflip/data-models';

const getCarryingCosts = (property: Property): number => {
  // CARRY COSTS
  const duration = property.carry.duration;

  // Sum all values except duration
  return Object.entries(property.carry).reduce(
    (acc, [key, value]) =>
      (acc += key === 'duration' ? 0 : parseFloat(value || 0) * duration),
    0,
  );
};

// don't include the subtotals
const getAcquisitionCosts = (property: Property): number =>
  Object.entries(property.acq).reduce(
    (acc, [key, value]) =>
      (acc += !key.includes('subtotal') ? Number(value || 0) || 0 : 0),
    0,
  );

// REPAIR COSTS
const getRepairCosts = (property: Property) => {
  return Object.values(property.repair).reduce(
    (acc, value) =>
      (acc += typeof value === 'boolean' ? 0 : Number(value || 0)),
    0,
  );
};

// SELLING COSTS
const getSellingCosts = (property: Property) =>
  Object.entries(property.selling).reduce(
    (acc, [key, value]) =>
      (acc += key.includes('ercent') ? 0 : Number(value || 0)),
    0,
  );

// OTHER COSTS
const getOtherCosts = (property: Property) =>
  Object.values(property.other).reduce(
    (acc, value) => (acc += Number(value || 0)),
    0,
  );

export const getSummaryTotalsByProperty = (property: Property): Calculation => {
  const calcVals = new Calculation();

  calcVals.totalCarryCosts = getCarryingCosts(property);
  calcVals.totalAcquisitionCosts = getAcquisitionCosts(property);
  calcVals.totalRepairCosts = getRepairCosts(property);
  calcVals.totalSellCosts = getSellingCosts(property);
  calcVals.totalOtherCosts = getOtherCosts(property);

  return calcVals;
};
