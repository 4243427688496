import { getSummaryTotalsByProperty } from '@smartflip/calculation-utils';
import { Property } from '@smartflip/data-models';

export const totalExpenses = (property: Property) => {
  const {
    totalAcquisitionCosts,
    totalCarryCosts,
    totalOtherCosts,
    totalRepairCosts,
    totalSellCosts,
  } = getSummaryTotalsByProperty(property);

  return (
    Number(property.summary.price) +
    totalAcquisitionCosts +
    totalCarryCosts +
    totalOtherCosts +
    totalRepairCosts +
    totalSellCosts
  );
};
